#Clock {
  background-color: #000f1f;
  border-radius: 50%;
  position: relative;
  height: 90vw;
  width: 90vw;
  max-height: 600px;
  max-width: 600px;

  .shadow {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-45deg);
    transform-origin: 50% 0%;
    opacity: 0.5;
    z-index: -1;
  }

  #InfoFace {
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 16.67%;
    top: 16.67%;
    width: 66.67%;
    height: 66.67%;
    background: linear-gradient(180deg,#272727,#1a1a1a);
    box-shadow: 0 0 50px rgba(0, 165, 255, .4);
    transition: box-shadow 1s;

    &.glowing {
      box-shadow: 0 0 50px rgba(218, 174, 0, .8);
    }

    .CurrentDate {
      height: 80px;
      line-height: 80px;
      text-transform: uppercase;
      font-size: 40px;
      margin: 0;
      margin-top: 30%;
      letter-spacing: 2px;
      color: #daae00;
      text-shadow: 0 0 20px #daae00;
    }

    .CurrentTime {
      height: 60px;
      line-height: 60px;
      font-size: 60px;
      margin: 0;
      letter-spacing: 2px;
      text-shadow: 0 0 20px rgba(10, 175, 230, 1);
    }
  }

  .indicator-container,
  .hours-container,
  .minutes-container,
  .seconds-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    div {
      position: absolute;
      transform-origin: 50% 100%;
    }
  }

  .hours-container {
    animation: rotatingHand 43200s infinite linear;
  }

  .minutes-container {
    animation: rotatingHand 3600s infinite linear;
  }

  .seconds-container {
    animation: rotatingHand 60s infinite linear;
  }

  .indicator {
    height: 50%;
    left: 49.5%;
    top: 0;
    width: .5%;

    &.majorIndicator:before {
      height: 8%;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 3%;
      content: "";
      background: #ffffff;
      box-shadow: 0 0 10px #ffffff;
    }
  }

  .hours {
    height: 43%;
    left: 48%;
    top: 7%;
    width: 4%;
    background: #daae00;
    box-shadow: 0 0 50px #daae00;
  }

  .minutes {
    height: 48%;
    left: 49%;
    top: 2%;
    width: 2%;
    background: #daae00;
    box-shadow: 0 0 30px #daae00;
  }

  .seconds {
    height: 50%;
    left: 49.75%;
    top: 0;
    width: .5%;
    background: #ffffff;
    box-shadow: 0 0 20px #ffffff;
  }
}

@keyframes rotatingHand {
  to {
    transform: rotate(1turn);
  }
}

@media (max-width: 480px) {
  #Clock {
    #InfoFace {
      .CurrentDate {
        margin-top: 37.5%;
        height: 40px;
        line-height: 30px;
        font-size: 30px;
      }
      .CurrentTime {
        height: 40px;
        line-height: 40px;
        font-size: 40px;
      }
    }
  }
}