.stopwatch {
  background-color: #000f1f;
  border-radius: 50%;
  position: relative;
  height: 90vw;
  width: 90vw;
  max-height: 600px;
  max-width: 600px;

  .shadow {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    background: linear-gradient(to bottom, black 0%, rgba(0, 0, 0, 0) 100%);
    transform: rotate(-45deg);
    transform-origin: 50% 0%;
    opacity: 0.5;
    z-index: -1;
  }

  .infoFace {
    border-radius: 50%;
    content: "";
    position: absolute;
    left: 16.67%;
    top: 16.67%;
    width: 66.67%;
    height: 66.67%;
    background: linear-gradient(180deg,#272727,#1a1a1a);
    box-shadow: 0 0 50px rgba(0, 165, 255, .4);
    transition: box-shadow 1s;

    .runTime {
      font-family: "Kode Mono", monospace;
      height: 46px;
      line-height: 46px;
      font-size: 46px;
      margin: 0;
      margin-top: 40%;
      letter-spacing: 2px;
      text-shadow: 0 0 20px rgba(10, 175, 230, 1);

      small {
        font-size: 0.6em;
      }
    }
  }

  .indicatorContainer,
  .hoursContainer,
  .minutesContainer,
  .secondsContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    div {
      position: absolute;
      transform-origin: 50% 100%;
    }
  }

  .hoursContainer {
    animation: rotatingHand 43200s infinite linear;
  }
  .minutesContainer {
    animation: rotatingHand 3600s infinite linear;
  }
  .secondsContainer {
    animation: rotatingHand 60s infinite linear;
  }
  .hoursContainer, .minutesContainer, .secondsContainer {
    animation-play-state: paused;
  }

  .indicator {
    height: 50%;
    left: 49.5%;
    top: 0;
    width: .5%;

    &.majorIndicator:before {
      height: 8%;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 3%;
      content: "";
      background: #ffffff;
      box-shadow: 0 0 10px #ffffff;
    }
  }

  .hours {
    height: 43%;
    left: 48%;
    top: 7%;
    width: 4%;
    background: #da0b00;
    box-shadow: 0 0 50px #da0b00;
  }

  .minutes {
    height: 48%;
    left: 49%;
    top: 2%;
    width: 2%;
    background: #da0b00;
    box-shadow: 0 0 30px #da0b00;
  }

  .seconds {
    height: 50%;
    left: 49.75%;
    top: 0;
    width: .5%;
    background: #ffffff;
    box-shadow: 0 0 20px #ffffff;
  }

  .controls {
    position: absolute;
    z-index: 1000;
    bottom: 30%;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    .startButton, .resetButton {
      height: 60px;
      width: 60px;
      font-size: 32px;
      line-height: 32px;
    }
  }

  .settingsButton {
    position: fixed;
    bottom: 10px;
    right: 10px;
  }

  &.reset {
    .hoursContainer, .minutesContainer, .secondsContainer {
      animation: none;
    }
  }

  &.running {
    .hoursContainer, .minutesContainer, .secondsContainer {
      animation-play-state: running;
    }

    .infoFace {
      animation: glowing 0.5s infinite alternate;
    }
  }
}

@keyframes rotatingHand {
  to {
    transform: rotate(1turn);
  }
}

@keyframes glowing {
  to {
    box-shadow: 0 0 50px rgba(218, 174, 0, .8);
  }
}

@media (max-width: 580px) {
  .stopwatch {
    .infoFace {
      .runTime {
        height: 40px;
        line-height: 40px;
        font-size: 40px;
      }
    }
  }
}

@media (max-width: 500px) {
  .stopwatch {
    .infoFace {
      .runTime {
        height: 32px;
        line-height: 32px;
        font-size: 32px;
      }
    }
  }
}

@media (max-width: 400px) {
  .stopwatch {
    .infoFace {
      .runTime {
        height: 24px;
        line-height: 24px;
        font-size: 24px;
      }
    }

    .controls {
      bottom: 30%;

      .startButton, .resetButton {
        height: 40px;
        width: 40px;
        padding: 8px;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}