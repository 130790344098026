.keyboardSettings {
  z-index: 2000;
  position: absolute;
  left: -5%;
  top: -5%;
  width: 110%;
  height: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  background: rgb(0 50 100 / 90%);
  border-radius: 15px;
  border: 1px solid rgb(0 150 255 / 100%);

  .keys {
    bottom: 190px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 150px;
      border: 1px solid rgb(0 150 255 / 100%);
      font-size: 80px;
      line-height: 80px;

      &.inSetting {
        background: rgb(0 150 255 / 100%);
        color: white;
      }

      .function {
        font-size: 1rem;
        line-height: 1rem;
      }
      .value {
        text-transform: uppercase;
      }
    }
  }

  .save {
    padding: 5px 20px;
    width: auto;
  }
}