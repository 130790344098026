body {
  text-align: center;
  font-family: "BenchNine", sans-serif;
  color: #ffffff;
  overflow: hidden;
  background-color: #0e0e0e;
  margin: 0;
}

.pageTitle {
  position: fixed;
  text-align: center;
  margin: 10px 0;
  width: 100%;
  top: 0;
  left: 0;
}

button {
  z-index: 1000;
  height: 60px;
  width: 60px;
  color: white;
  background-color: rgb(255 255 255 / 10%);
  border: none;
  padding: 0;
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background-color: rgb(255 255 255 / 20%);
  }
  &:active:not(:disabled), &.pressed {
    background-color: rgb(255 255 255 / 30%);
    transform: scale(0.85);
  }
}

.fullScreenButton {
  position: fixed;
  top: 10px;
  right: 10px;
}

.pageToggleButton {
  position: fixed;
  top: 10px;
  left: 10px;
}

#particles {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.footer {
  position: fixed;
  bottom: 10px;
  width: 100%;
}

.Now {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .desktopOnly {
    display: none !important;
  }
}